<script>
    import { getContext } from "svelte";
    import { displayVariant, EUR } from "./util";

    const cart = getContext("cart");
    export let onCheckout;
    export let allowModify = true;
    export let allowCheckout = true;

    const orderPossible = (async () => {
        const response = await fetch("/api/can_order");
        return (await response.json()) == true && isOpen();
    })();

    function isOpen() {
        let now = new Date();
        let day = now.getDay();
        let hour = now.getHours();
        let minute = now.getMinutes();
        let early = hour < 12 || (hour == 11 && minute < 30);
        if (day == 0) {
            return !early && (hour < 21 || (hour == 21 && minute < 30));
        } else {
            return !early && hour < 22;
        }
    }
</script>

<h3>Warenkorb</h3>

{#if $cart}
    {#if $cart.empty}
        <p>Der Warenkorb ist leer.</p>
    {:else}
        <ul>
            {#each $cart.items as item, i}
                <li>
                    {item.quantity} x {item.name}
                    {#if item.variant}
                        ({displayVariant(item.variant)})
                    {/if}
                    –
                    {EUR.format(item.price)}
                    <!-- svelte-ignore a11y-invalid-attribute -->
                    {#if allowModify}
                        <a
                            href="javascript:void(0)"
                            class="text-red-500 hover:text-red-700 hover:font-bold"
                            on:click={() =>
                                cart.update((cart) => {
                                    cart.remove(i);
                                    return cart;
                                })}>(entfernen)</a
                        >
                    {/if}
                    {#if item.extras || item.instructions}
                        <ul class="pl-4">
                            {#if item.instructions}
                                <li>
                                    <em
                                        >"<strong>{item.instructions}</strong
                                        >"</em
                                    >
                                </li>
                            {/if}
                            {#each item.extras as extra}
                                <li>
                                    + {extra.name}
                                </li>
                            {/each}
                        </ul>
                    {/if}
                </li>
            {/each}
        </ul>
        <p>Gesamt: {EUR.format($cart.total)}</p>
        {#await orderPossible then isOrderPossible}
            {#if isOrderPossible}
                {#if allowCheckout}
                    <button
                        class="bg-green-500 text-white p-2 m-2 rounded-md"
                        on:click={onCheckout}>Zur Kasse</button
                    >
                {/if}
            {:else}
                <p>Wir haben momentan leider geschlossen.</p>
            {/if}
        {/await}
    {/if}
{/if}
